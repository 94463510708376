import request from '@/utils/request'

// api地址
const api = {
    getSignatures: 'qywx/getSignatures',
    getQywxUserId: 'qywx/getQywxUserId',
    getUserInfoByQywxUserId: 'qywx/getUserInfoByQywxUserId',
    bindUser: 'qywx/bindUser',
    getUserInfo: 'qywx/getUserInfo',
    setUserLabel: 'qywx/setUserLabel',
    getUserIndexCount: 'qywx/getUserIndexCount',
    orderList: 'qywx/orderList',
    orderDetail: 'qywx/orderDetail',
    goodsList: 'qywx/goodsList',
    addGoodsWord: 'qywx/addGoodsWord',
    getQywxCartCategoryId: 'qywx/getQywxCartCategoryId',
    addQywxOrder: 'qywx/addQywxOrder',
    getCatalogVinInfo: 'qywx/getCatalogVinInfo',
    getCatalogBomInfo: 'qywx/getCatalogBomInfo',
    getGoodsListFromBuyCode: 'qywx/getGoodsListFromBuyCode',
    getInventoryListForTypeC: 'qywx/getInventoryListForTypeC',
}

export const getSignatures = (param) => {
    return request({
        url: api.getSignatures,
        method: 'get',
        params: param,
    })
}

export const getQywxUserId = (param) => {
    return request({
        url: api.getQywxUserId,
        method: 'get',
        params: param,
    })
}

export const getUserInfoByQywxUserId = (param) => {
    return request({
        url: api.getUserInfoByQywxUserId,
        method: 'get',
        params: param,
    })
}

export const bindUser = (param) => {
    return request({
        url: api.bindUser,
        method: 'post',
        data: param,
    })
}

export const getUserInfo = (param) => {
    return request({
        url: api.getUserInfo,
        method: 'get',
        params: param,
    })
}

export const setUserLabel = (param) => {
    return request({
        url: api.setUserLabel,
        method: 'post',
        data: param,
    })
}

export const getUserIndexCount = (param) => {
    return request({
        url: api.getUserIndexCount,
        method: 'get',
        params: param,
    })
}

export const orderList = (param) => {
    return request({
        url: api.orderList,
        method: 'get',
        params: param,
    })
}

export const orderDetail = (param) => {
    return request({
        url: api.orderDetail,
        method: 'get',
        params: param,
    })
}

export const goodsList = (param) => {
    return request({
        url: api.goodsList,
        method: 'get',
        params: param,
    })
}

export const addGoodsWord = (param) => {
    return request({
        url: api.addGoodsWord,
        method: 'post',
        data: param,
    })
}

export const getQywxCartCategoryId = (param) => {
    return request({
        url: api.getQywxCartCategoryId,
        method: 'get',
        params: param,
    })
}

export const addQywxOrder = (param) => {
    return request({
        url: api.addQywxOrder,
        method: 'post',
        data: param,
    })
}

export const getCatalogVinInfo = (param) => {
    return request({
        url: api.getCatalogVinInfo,
        method: 'get',
        params: param,
    })
}

export const getCatalogBomInfo = (param) => {
    return request({
        url: api.getCatalogBomInfo,
        method: 'get',
        params: param,
    })
}

export const getGoodsListFromBuyCode = (param) => {
    return request({
        url: api.getGoodsListFromBuyCode,
        method: 'get',
        params: param,
    })
}

export const getInventoryListForTypeC = (param) => {
    return request({
        url: api.getInventoryListForTypeC,
        method: 'get',
        params: param,
    })
}
