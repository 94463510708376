<template>
	<div class="order-details">
		<!-- 订单信息 -->
		<div class="header">
			<span class="code">订单号：{{order.order_no}}</span>
			<span class="tag-blue">{{order.state_text}}</span>
		</div>
		<el-divider></el-divider>
		<div class="main-info">
			<div class="info-item w100">
				<span>收货人姓名：</span><em>{{order.address.name}}</em>
			</div>
			<div class="info-item w100">
				<span>联系电话：</span><em>{{order.address.phone}}</em>
			</div>
			<div class="info-item w100">
				<span>收货地址：</span><em>{{order.address.region.province+order.address.region.city+order.address.region.region+order.address.detail}}</em>
			</div>
            <div v-if="showExpress()">
                <div class="info-item w50">
                    <span>物流公司：</span><em>{{order.express.express_name}}</em>
                </div>
                <div class="info-item w50">
                    <span>物流单号：</span><em>{{ order.express_no }}</em>
                </div>
                <div class="info-item w50">
                    <span>发货时间：</span><em>{{ order.delivery_time }}</em>
                </div>
            </div>
            <div v-for="(item, idx) in deliveryList.filter(e => e.express_no)" :key="idx">
                <div class="info-item w100">
                    <span>发货仓库：</span><em>{{item.warehouse_name}}</em>
                </div>
                <div class="info-item w50">
                    <span>物流公司：</span><em>{{item.express_name}}</em>
                </div>
                <div class="info-item w50">
                    <span>物流单号：</span><em>{{item.express_no}}</em>
                </div>
            </div>
			<div class="info-item w100">
				<span>关联退单：</span>
                <em v-for="(item, idx) in order.refund" :key="idx">{{item.order_refund_no}}</em>
			</div>
			<div class="info-item w100">
				<span>买家留言：</span><em>{{ order.buyer_remark }}</em>
			</div>
			<div class="info-item w40">
				<span>订单金额：</span><em>￥{{ order.pay_price }}</em>
			</div>
			<div class="info-item w30">
				<span>运费：</span><em>￥{{ order.express_price }}</em>
			</div>
			<div class="info-item w30">
				<span>数量：</span><em>{{order.goods.reduce((sum, e) => sum + e.total_num, 0)}}</em>
			</div>
		</div>
		<el-divider></el-divider>
		<!-- 订单列表 -->
		<div class="order-list">
			<div class="order-list-item" v-for="(item,index) in order.goods.slice((currentPage-1)*pagesize,5*pagesize)" :key="index">
				<div class="title">
					<div class="name line1">{{item.goods_name}}</div>
					<div class="code line1">{{item.buy_code}}</div>
				</div>
				<div class="content">
					<div class="left">
						<span class="price">￥{{item.grade_goods_price > 0 ? item.grade_goods_price : item.goods_price }}</span>
					</div>
					<div class="right">
						<span class="number">x{{item.total_num}}</span>
                        <span class="number" v-if="getRefundNum(item) > 0">退货数量：x{{getRefundNum(item)}}</span>
					</div>
				</div>
			</div>
		</div>
		<van-pagination v-model="currentPage" :total-items="order.goods.length" :show-page-size="pagesize" force-ellipses />
		<el-divider></el-divider>
		<div class="time">下单日期：{{order.create_time.split(' ')[0]}}</div>
		<div class="total">
			<span>总计：<em>￥{{order.pay_price}}</em></span>
			<span>数量：<em>{{order.goods.reduce((sum, e) => sum + e.total_num, 0)}}</em>件</span>
		</div>
	</div>
</template>

<script>
    import * as QywxApi from '@/api/qywx'
    import {DeliveryStatusEnum, OrderStatusEnum, PayStatusEnum} from "../../../common/enum/order";
    import {inArray} from "../../../utils/util";
	export default {
		data() {
			return {
                order: {},
                deliveryList: [],
				currentPage: 1,
                pagesize: 5
			}
		},
        watch: {
            // 监听路由变化
            $route(to) {
                this.order = {}
                this.deliveryList = []
                this.getOrderDetail(to.query.order_id)
            }
        },
        created() {
            this.getOrderDetail(this.$route.query.order_id)
        },
        methods: {
            getOrderDetail(order_id) {
                if (!order_id) return
                this.order = {}
                QywxApi.orderDetail({ orderId: order_id }).then(res => {
                    this.order = res.data.detail
                    this.formatOrder()
                })
            },
            formatOrder() {
                let val = this.order
                let deliveryList = []
                let expressList = val.fawexpress
                val.goods.forEach(item => {
                    item.is_refunded = this.getRefunded(item)
                    item.is_refund = this.getRefund(item)
                    item.delivery.forEach(d => {
                        if(!deliveryList.find(i => i.tds_no == d.tds_no)){
                            deliveryList.push({
                                tds_no: d.tds_no,
                                warehouse_name: d.warehouse_name,
                                warehouse_no: d.warehouse_no,
                            })
                        }
                    })
                })
                deliveryList.forEach(item => {
                    let express = expressList.find(d => d.tds_no == item.tds_no)
                    if(express) {
                        item.express_name = express.express_name
                        item.express_no = express.express_no
                        item.status = express.status
                    }
                })
                this.deliveryList = deliveryList
            },
            hasExpress() {
                let record = this.order
                return record.pay_status == PayStatusEnum.SUCCESS.value
                    && record.delivery_status == DeliveryStatusEnum.DELIVERED.value
                    && !inArray(record.order_status, [OrderStatusEnum.CANCELLED.value, OrderStatusEnum.APPLY_CANCEL.value])
            },
            showExpress() {
                let record = this.order
                return this.hasExpress() && record.express_no!='faw'
            },
            getRefunded (goods) {
                const total_refund_num = goods.refund.reduce((sum, e) => sum + Number(e.refund_num), 0)
                return total_refund_num >= goods.total_num
            },
            getRefund(goods) {
                const total_refund_num = goods.refund.reduce((sum, e) => sum + Number(e.refund_num), 0)
                return total_refund_num > 0
            },
            getRefundNum(row) {
                return row.refund.reduce((acr, cur) => acr + cur.refund_num, 0)
            },
        }
    }
</script>

<style lang="scss" scoped>
	@import "@/assets/css/wecom.scss";
</style>
